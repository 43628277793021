import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import Brands from "../components/base/Brands";
import WhyChooseUs from "../components/base/WhyChooseUs";
import Testimonial from "../components/base/Testimonial"
import CustomFooter from "../components/CustomFooter";

export const SecurityAssessmentsPageTemplate = ({
  seo,
  hero,
  contactus,
  aboutsection,
  rvsection,
  scheduleSection,
  cybersection,
  appsection,
  netsection,
  fixedimagesection
}) => (
    <div className="custompages">
      <SEO title={seo.title} description={seo.description} titlePostfix="%s" blogservice={true} />
      <HeroOther data={hero} />
      <section className="section">

        <div className="container">
          <div className="columns">
            <div className="column">
              <SubTitle textAlign="center" title={contactus.title} />
            </div>
          </div>
          <div className="columns">
            <div className="column  has-text-centered">
              <a href={contactus.buttonlink} >
                <button class="btn-light">{contactus.buttontxt}</button>
              </a>
            </div>
          </div>

        </div>
      </section>




      <section className="section" style={{ background: `${scheduleSection.sectionbg}`, paddingBottom: '2rem' }}>
        <div className="container scedulesection">


          <div className="columns" >
            <div className="column is-8 " style={{ margin: "0rem 0" }}>

              {
                scheduleSection.description.list.map((description, i) => {
                  return (
                    <div key={i}>
                      <p style={{ margin: "1rem 0", color: "#53c5d1", }}>{description.title}</p>
                    </div>
                  )
                })
              }

              <a href={scheduleSection.buttonlink} >
                <button style={{ marginTop: 10 }} class="btn-light">{scheduleSection.buttontxt}</button>
              </a>
            </div>

            <div className="column mobile-center has-text-centered hero-image">
              <div style={{ maxWidth: 250, height: 30, marginTop: 104 }}>
                <PreviewCompatibleImage imageInfo={{ image: '/img/Cypress-Data-Defense-Website-Yellow-Alien-5.png' }} />

              </div>
            </div>
          </div>

        </div>
      </section>






      <section className="section" style={{ background: `${aboutsection.sectionbg}`, paddingBottom: '2rem' }}>
        <div className="container">
          <div style={{ marginTop: "0rem" }}>
            <SubTitle title={aboutsection.title} color={aboutsection.textcolor} margin=".5rem 0" />
            {
              aboutsection.description.list.map((description, i) => {
                return (
                  <div key={i}>
                    <p style={{ margin: "1rem 0", color: "#ffffff", }}>{description.title}</p>
                  </div>
                )
              })
            }
          </div>

          <div className="columns" style={{ paddingTop: '0.5rem' }} >
            <div className="column  ">
              <a href={aboutsection.buttonlink} >
                <button class="">{aboutsection.buttontxt}</button>
              </a>
            </div>
          </div>

        </div>
      </section>

      <section className="section  scedulesection" style={{ background: `${rvsection.sectionbg}` }}>
        <div className="container">
          <div className="columns">
            <div className="column is-3 ">
            </div>
            <div className="column is-6 ">
              <div style={{ textAlign: 'center' }}>
                <SubTitle title={rvsection.subheader} color="#fff" />
              </div>
            </div>
            <div className="column is-3 ">
            </div>
          </div>
          <Descriptions descriptions={rvsection.sidedescriptions} color={rvsection.textcolor} margin="1rem 0 0" />
          <div className="columns">
            <div className="column is-8">
              <ul style={{ listStyleType: 'disc', paddingLeft: '3rem', color: '#53c5d1' }}>
                {[
                  'Manual and Automated Testing',
                  'Static/Dynamic/Hybrid Testing',
                  'Advice on how to develop a Secure Software Development Lifecycle (SSDLC)',
                ].map((el, i) => <li style={{ marginTop: '1rem' }} key={i}>{el}</li>)}
              </ul>
              <Descriptions descriptions={rvsection.descriptions} color={rvsection.textcolor} margin="1rem 0 0" />
            </div>
            <div className="column is-4 mobile-center has-text-centered hero-image">
              <div style={{ maxWidth: "100%", height: 90, marginTop: 10 }}>
                <PreviewCompatibleImage imageInfo={{ image: '/img/security-hybrid.png' }} />

              </div>

            </div>
          </div>

          <div style={{ marginTop: '1rem' }}>
            <a href={contactus.buttonlink}>
              <button className="btn-light">Secure Your Applications Today &#10230;</button>
            </a>
          </div>
        </div>
      </section>


      <Brands />
      <WhyChooseUs
        title="With our web application and mobile application security assessment services, you can rest assured that your apps are secure.
        "
        list={[{
          title: 'We understand code and how applications are built.',
          para: 'Most security personnel come from network or infrastructure backgrounds and they don’t understand apps, but we do. All of our security engineers are from development backgrounds. We have developed apps and know the shortcuts developers take. This enables us to secure your web and mobile applications and help you get a secure DevOps pipeline.'
        }, {
          title: 'We are security experts.',
          para: 'Our engineers regularly instruct for SANS, where they teach developers and organizations how to properly perform security assessments on applications.Using our expertise can help you secure your development lifecycle.',
        },
        {
          title: 'We frequently spread the word about application security.',
          para: 'Our highly-experienced application security assessment experts often speak at conferences and events throughout North America, Europe, and Asia.',
        }]} />
      <Testimonial />
      <CustomFooter data={{
        title: 'Want to Leverage the Most Reliable Application Security Assessment Services?',
        subtitle: '',
        para: [
          'It’s time to bring in the experts in application security to ensure that all of your web and mobile apps are secure through in-depth testing methodologies.'

        ],
        linkTitle: 'Talk to Our Experts Today'
      }} />


    </div>
  )

SecurityAssessmentsPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  scheduleSection: PropTypes.object,
  aboutsection: PropTypes.object,
  rvsection: PropTypes.object,
  cybersection: PropTypes.object,
  appsection: PropTypes.object,
  netsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const SecurityAssessmentsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SecurityAssessmentsPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        scheduleSection={frontmatter.scheduleSection}
        aboutsection={frontmatter.aboutsection}
        rvsection={frontmatter.rvsection}
        cybersection={frontmatter.cybersection}
        appsection={frontmatter.appsection}
        netsection={frontmatter.netsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

SecurityAssessmentsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default SecurityAssessmentsPage

export const pageQuery = graphql`
  query SecurityAssessmentsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "security-assessments" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero{
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        scheduleSection{
          sectionbg
          textcolor
          description{
            list{
              title
            }
          }
          buttontxt
          buttonlink
        }
        aboutsection {
          sectionbg
          textcolor
          title
          description{
            list{
              title
            }
          }
          buttontxt
          buttonlink
          image {
            childImageSharp {
              fluid(maxWidth: 260, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        rvsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          subheader2
          descriptions
          sidedescriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }


      }
    }
  }
`
